
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "User Name",
      email: "Email"
    };

    const records = ref({
      data: []
    });

    const record = reactive({
      name: "",
      email: ""
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/notification-emails`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/notification-emails", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/notification-emails/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http
        .post(`/api/notification-emails/${id}`, { _method: "DELETE" })
        .then(res => {
          fetchRecords();
        });
    }

    onMounted(() => {
      fetchRecords();
    });

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord
    };
  }
});
